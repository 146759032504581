import React from 'react';

export default function Footer() {
  return (
    <footer className="footer text-center">
      <h6 className="right-reserve mb-0">
        © 2019 Jukin Media, Inc. All rights reserved.
      </h6>
    </footer>
  );
}
