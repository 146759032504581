import React from 'react';
import config from '../../config';

export default function SocialLinks(props) {
  return (
    <section className="contact-section footer">
      <div className="container">
        {props.siteLogoClass && (
          <div className={`social d-flex justify-content-center`}>
            <h2 className="brand-heading">Our Brands</h2>
          </div>
        )}
        {!props.siteLogoClass && (
          <div
            className={`social d-flex justify-content-center ${props.siteLogoClass}`}
          >
            <img
              src="/jukin-media/jukin-media-logo.png"
              srcSet="/jukin-media/jukin-media-logo@2x.png, /jukin-media/jukin-media-logo@3x.png"
              alt="Jukin Media"
              className="brand-logo"
            ></img>
          </div>
        )}
        <div className="social footer-brand-list d-flex justify-content-center flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
          <div className="row fan-view">
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12"></div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6">
              <div className="brand-footer-image">
                <a href="https://www.peopleareawesome.com/">
                  <img
                    src="/paa/paa.png"
                    srcSet="/paa/paa@2x.png, /paa/paa@3x.png"
                    alt="People Are Awesome"
                    className="image1"
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 ">
              <div className="brand-footer-image">
                <a href="https://www.failarmy.com/">
                  <img
                    src="/failarmy/failarmy.png"
                    srcSet="/failarmy/failarmy@2x.png, /failarmy/failarmy@3x.png"
                    alt="Fail Army"
                    className="image2"
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 ">
              <div className="brand-footer-image">
                <a href="https://thepetcollective.com/">
                  <img
                    src="/tpc/tpc.png"
                    srcSet="/tpc/tpc@2x.png, /tpc/tpc@3x.png"
                    alt="The Pet Collective"
                    className="image3"
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 ">
              <div className="brand-footer-image">
                <a href="https://www.thisishappeningofficial.com/">
                  <img
                    src="/all/this-is-happening.jpg"
                    alt="happening"
                    className="image4"
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 ">
              <div className="brand-footer-image">
                <a href="https://www.pokemyheart.com/">
                  <img
                    src="/pmh/pmh.png"
                    srcSet="/pmh/pmh@2x.png, /pmh/pmh@3x.png"
                    alt="Poke My Heart"
                    className="image5"
                  ></img>
                </a>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12"></div>
          </div>
        </div>

        <div className="social icons d-flex justify-content-center">
          {config.socialLinks.map(social => {
            const { icon, url, name } = social;
            return (
              <a key={url} href={url} alt={name}>
                <i className={`fab ${icon}`}></i>
              </a>
            );
          })}
        </div>

        <h6 className="mb-0 text-center Privacy-Policy-Ter">
          <a href="/privacy_policy">Privacy policy</a> &nbsp;&nbsp; &nbsp; |
          &nbsp;&nbsp; &nbsp;
          <a href="/terms_condition">Terms</a>
        </h6>
      </div>
    </section>
  );
}
